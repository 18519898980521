<template>
  <div :id="id + '_container'">
    <v-autocomplete
      v-model="localValue"
      :items="personOptions"
      :loading="loading"
      :search-input.sync="search"
      hide-no-data
      item-text="name"
      item-value="id"
      :label="fieldAttributes.name"
      :placeholder="placeholder"
      clearable
      return-object
      outlined
      dense
      :id="id"
      :rules="rules"
      :error-messages="errors"
    >
      <template slot="item" slot-scope="{ parent, item }">
        <v-list-item-content
          :id="id + '_' + item.id"
          :order-id="id + '_' + personOptions.indexOf(item)"
          :data-type="id + '_optionsList'"
        >
          <v-list-item-title v-html="parent.genFilteredText(item.name)">
          </v-list-item-title>
        </v-list-item-content> </template
    ></v-autocomplete>
    <input
      v-if="localValue != 'object'"
      type="hidden"
      :value="localValue"
      :name="field"
      :id="id + '_value'"
    />
  </div>
</template>
<script>
import { getFieldId, getPlaceholder } from "@/js/helper.js";
import { getSelectRules } from "@/js/validators.js";
import debounce from "debounce";

export default {
  props: ["result", "field", "fieldAttributes", "value", "fieldAttrInput"],
  data() {
    return {
      personOptions: [],
      search: "",
      loading: false,
      objectValue: {},
      errors: [],
      updated: false,
    };
  },
  created() {
    let errors = [];
    this.rules.forEach((rule) => {
      let text = rule(this.value);
      if (typeof text == "string") {
        errors.push(text);
      }
    });
    this.errors = errors;
    if (this.localValue != "") {
      this.makeSearch(this.localValue, this);
      this.updated = true;
    }
  },

  computed: {
    placeholder() {
      return getPlaceholder(this.fieldAttrInput);
    },
    localValue: {
      get() {
        let localValue = "";
        if (typeof this.value != "undefined") {
          localValue = this.value;
        }
        return localValue;
      },
      set(newVal) {
        console.log(newVal);
        this.updated = true;
        this.objectValue = newVal;
        this.$emit("input", newVal.id);
        this.$emit("revalidate");
      },
    },

    rules() {
      return getSelectRules(this.fieldAttrInput, this.result, this);
    },
    id() {
      return getFieldId(this.fieldAttrInput, this.result, this.field);
    },
  },
  methods: {
    makeSearch: async (value, self) => {
      // Handle empty value
      self.updated = false;
      if (!value) {
        self.personOptions = [];
        //self.value = "";
        self.$emit("input", "");
        return;
      }
      // Items have already been requested
      if (self.loading) {
        return;
      }

      self.loading = true;

      self
        .axios({
          method: "post",
          url: self.result.tableUrl,
          data: {
            requestType: "ajax",
            function: "getAssociatedRemoteList",
            searchValue: value,
          },
        })
        .then((response) => {
          self.personOptions = response.data.result.json.list;
        })
        .catch(() => {})
        .finally(() => (self.loading = false));
    },
    checkInList(search) {
      return this.personOptions.some((el) => el.name == search);
    },
  },
  watch: {
    value: function () {
      this.errors = [];
    },
    localValue: function () {
      this.errors = [];
    },
    search(value) {
      if (!value || this.checkInList(value)) {
        return;
      }
      if (value.includes(this.objectValue.text)) {
        value = this.objectValue.text;
      }
      debounce(this.makeSearch, 500)(value, this);
    },
  },
};
</script>